import React from 'react';
import "./nlp-developer.css";

const indexPage = (props) => {

  return (
   <section className="job-posting">
       <h1>Machine Learning(NLP)Developer</h1>
       <p>Heirlume is boldly going where no one has gone before: we are making the trademark search and registration process accessible to everyone.  Trademarking typically involves expensive lawyers and is a slow and complex process.  We are greatly simplifying it, with the goal of making it as straightforward as domain registration.</p>
       <p>We are a rapidly growing start-up and are excited to grow our team with the addition of a <strong>Machine Learning (NLP) Developer</strong> to lead the development of our machine learning applications.  Strong experience with natural language processing (NLP) is required.  The right person for job must embrace the concept of team.  We are looking to hire someone who will grow with us, taking initiative and ownership of new roles and processes.  This is a phenomenal opportunity to build game-changing technology as part of a tight knit, supportive team. We are an early stage company with ample opportunity for employee growth.</p>
       <h2>About the Job</h2>
       <h3>Responsibilities:</h3>
       <ul>
           <li>Reporting to the CTO, you will collaborate with the CEO, CTO, designers, developers, contractors, and end users.</li>
           <li>Learn about our domain, the problems we are solving, and jump onboard by embracing our ambitious journey.</li>
           <li>Design and implement the natural language processing and machine learning solutions that are the core part of our trademark search and registration platform.</li>
           <li>Research and build proof of concepts for performant solutions to NLP problems.</li>
           <li>Create brilliant software, be your own QA, deploy your work to production, watch our users fall in love!</li>
           <li>Be prepared to grow and take on increasing responsibility.  We need someone who will take initiative, excel at working both independently and collaboratively, and demonstrate leadership while owning our products.</li>
           <li>Recommend and optimize team processes to ensure we meet commitments and deliver high quality software.</li>
           <li>Be flexible and open, as our priorities are constantly changing.</li>
       </ul>
       <h3>Benefits and Compensation:</h3>
       <ul>
           <li>The company is all remote, with staff distributed in North America.</li>
           <li>Flexible work hours.</li>
           <li>Flexible health benefits.</li>
           <li>Competitive salary.</li>
       </ul>
       <h2>Qualifications and Experience</h2>
       <h3>Required:</h3>
       <ul>
           <li>3+ years of commercial software development experience focused on machine learning.</li>
           <li>Master’s or further education in Mathematics, Computer Science or related field.</li>
           <li>Excellent software engineering skills in Python.</li>
           <li>Understanding of the ML development lifecycle, including infrastructure and operations.</li>
           <li>Experience building applications with NLP (e.g., classification, information extraction, clustering, named entity recognition (NER), topic modeling).</li>
           <li>Experience with relevant Python frameworks and libraries (e.g., Scikit-learn, PyTorch, NumPy, Pandas, NLTK, SciPy, NumPy).</li>
           <li>Full development team experience:  Git, code reviews, giving and receiving feedback, sprints, prioritizing work.</li>
           <li>Enthusiasm to learn what you don’t know to get up to speed quickly.</li>
       </ul>
       <h3>Preferred:</h3>
       <ul>
           <li>Experience in any of our tech:  Java, Python, Django, Postgres, Elasticsearch.</li>
           <li>Using novel ways to solve NLP and data problems (e.g., graph databases).</li>
           <li>Continuous delivery and integration, centralized logging, configuration management.</li>
           <li>Experience with Google Cloud Platform.</li>
       </ul>
       <h2>Join the Team</h2>
       <p>If you want to join a company that is building massively disruptive technology, reach out today!</p>
       <p>Heirlume is a female led startup which strongly values diversity, equity, and inclusion.  We are looking for someone who shares these values to join our family.  We are striving to build a team that is racially diverse, queer-positive, and multi-able.  We encourage candidates to self-identify in their cover letters.  If there are ways we can make the hiring process more accessible for you, please let us know.</p>
       <p>To apply, send your resume and cover letter to <a href="mailto:hr@heirlumeco.com">hr@heirlumeco.com</a> with “Machine Learning (NLP) Developer” in the subject line.</p>
   </section>
  )
}

export default indexPage
